/**
 * This provider is used to determine what colour to show the
 * content editor has selected a colour scheme from the page editor.
 * @NOTE:  This is not related to the Chakra Theme Provider.
 */

import React, { useContext } from "react"

export const MPThemeContext = React.createContext({ color: "default" })

export const MPThemeProvider = ({ children, theme = null }) => {
  return (
    <MPThemeContext.Provider value={{ color: theme ? theme : "default" }}>
      {children}
    </MPThemeContext.Provider>
  )
}

export const useMPTheme = () => {
  return useContext(MPThemeContext)
}
