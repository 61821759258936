import * as React from "react"
import { Link } from "gatsby"
import { Button, Flex, Text } from "@chakra-ui/react"
import { Heading } from "../../components/Heading"

const CompletePage = () => {
  return (
    <Flex
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor="gray.20"
    >
      <Heading color="brand.200" fontSize={48}>
        Congratulations!
      </Heading>

      <Text mb={12}>Your account has been created successfully.</Text>

      <Button
        as={Link}
        to="/"
        colorScheme="blue"
        style={{ textDecoration: "none " }}
      >
        Return to website
      </Button>
    </Flex>
  )
}

export default CompletePage
