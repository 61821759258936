import React from "react"
import { Text } from "@chakra-ui/react"
import { useMPTheme } from "../../context/theme/MPThemeProvider"

export function Heading({ children, ...rest }) {
  const { color } = useMPTheme()

  return (
    <Text
      color={color === "default" ? "brand.200" : color}
      as="h1"
      mb={4}
      fontSize="32px"
      lineHeight={1.2}
      fontWeight="bold"
      fontFamily="Sansation, Helvetica, Arial, sans-serif"
      {...rest}
    >
      {children}
    </Text>
  )
}
